<template>
  <div class="my-plan-group-card">
    <div class="avatar-group">
      <div class="avatar circle-32px">
        <img :id="cardData._id + '-avatar-' + cardData.master.serial" :src="cardData.master.pic_url" alt="avatar" tabindex="0">
        <b-popover :target="cardData._id + '-avatar-' + cardData.master.serial" triggers="hover" placement="top">
          {{ masterName }}
        </b-popover>
      </div>
      <div class="avatar circle-32px" v-for="(item, index) in cardData.members" :key="index">
        <img :id="cardData._id + '-avatar-' + item.serial" :src="item.pic_url" alt="avatar" tabindex="0">
        <b-popover :target="cardData._id + '-avatar-' + item.serial" triggers="hover" placement="top">
          {{ memberName(item) }}
        </b-popover>
      </div>
    </div>
    <h5 class="fs-h5" v-text="cardData.name"></h5>
    <div class="desc fs-14px">
      <span v-text="durationDays"></span>
      <span> | </span>
      <span v-text="parseDateStr(cardData.startDate)"></span>
      <span> ~ </span>
      <span v-text="parseDateStr(cardData.endDate)"></span>
    </div>
    <b-button class="view-plan-btn" variant="secondary" v-text="btnName" @click="linkToDetail"></b-button>
  </div>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: 'MyPlanGroupCard',
  props: {
    cardData: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    cardType: {
      require: true,
    },
  },
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
    ...mapState(['user']),

    masterName() {
      // if( this.user.serial === this.cardData.master.serial ) {
      //   return '丟系哇啦';
      // } else {
      //   return this.cardData.master.name;
      // }
      return this.cardData.master.name;
    },
    durationDays() {
      switch (this.cardData.durationDays) {
        case 1:
          return '單攻';
        default:
          return this.cardData.durationDays + '天';
      }
    },
    btnName() {
      switch (this.cardType) {
        case '登山計畫':
          return '瀏覽登山計畫';
        case '參加的活動':
          return '瀏覽活動內容';
        default:
          return '發生錯誤!';
      }
    }
  },
  methods: {
    parseDateStr: function (date) {
      let t = moment(date);
      return t.format('YYYY/MM/DD(dd)');
    },
    memberName: function (item) {
      // if( this.user.serial === item.serial ) {
      //   return '丟系哇啦';
      // } else {
      //   return item.name;
      // }
      return item.name;
    },
    // numberToChinese: function (num) {
    //   const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    //   const chineseTens = ['', '', '二十', '三十', '四十', '五十', '六十', '七十', '八十', '九十'];
    //
    //   if (num < 10) {
    //     return chineseNumbers[num];
    //   } else if (num < 100) {
    //     const ten = Math.floor(num / 10);
    //     const digit = num % 10;
    //     return chineseTens[ten] + (digit === 0 ? '' : chineseNumbers[digit]) + '天';
    //   } else {
    //     return '異常天數';
    //   }
    // },
    linkToDetail: function () {
      this.$router.push({ name: 'GroupDetail', params: { id: this.cardData._id }})
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.my-plan-group-card {
  position: relative;
  box-shadow: 0px -1px 0px 0px $color-accent inset;
  padding: 1rem 0;
  @include smaller-than-small {
    display: flex;
    flex-direction: column;
    background: $color-white;
    padding: 1.5rem 1rem;
  }
  .avatar-group {
    display: flex;
    @include smaller-than-small {
      order: 3;
      margin-bottom: 1rem;
    }
    .avatar {
      flex: 0 0 auto;
      margin-bottom: .5rem;
      &:not(:last-child) {
        margin-right: -.5rem;
      }
      @include smaller-than-small {
        &.circle-32px {
          width: 25px;
          height: 25px;
        }
        margin-bottom: .25rem;
        &:not(:last-child) {
          margin-right: -.25rem;
        }
      }
    }
  }
  h5.fs-h5 {
    @include smaller-than-small {
      order: 2;
      font-size: 1.25rem;
    }
  }
  .desc {
    color: $color-bk-tp-85;
    @include smaller-than-small {
      order: 1;
      color: $color-bk-tp-50;
      font-size: $font-small;
      margin-bottom: .5rem;
    }
  }
  .view-plan-btn {
    order: 4;
    @include bigger-than-small {
      position: absolute;
      top: calc(50% - 25px);
      right: 0;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.my-plan-group-card {
}
</style>
