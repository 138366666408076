<template>
  <div class="my-trip-plan-link-block">
    <div class="trip-plan-link-item" v-for="(item, index) in planListData" :key="index">
      <div class="link-main">
        <img :src="item.picUrl" alt="plan picture">
        <div class="info-group">
          <div class="name fs-h5"><router-link :to="item.path" v-text="item.name" @click="cardClicked(planType)"></router-link></div>
          <div class="text-group d-none">
            <div class="text-item">
              <Icon name="distance" size="24" />
              <span>32.5 km</span>
            </div>
            <div class="text-item">
              <Icon name="time" size="24" />
              <span>38.2 hr</span>
            </div>
          </div>
        </div>
      </div>
      <div class="link-footer fs-body-14" v-if="planType === '舊的行程頁' || '舊的已加入'">
        <span v-text="parseDateStr(item.startDate)"></span><span v-show="item.startDate !== item.endDate"> - <span v-text="parseDateStr(item.endDate)"></span></span>
      </div>
      <button class="delete-btn" @click="deleteBtnClicked(item.serial, item.name, index)" v-if="planType === '舊的行程頁'">
        <Icon name="volume" size="24" />
      </button>
    </div>

  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'MyPlanList',
  props: {
    planType: {
      required: true,
    },
    planListData: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    cardClicked: function(type) {
      console.log(type)
      // window.trackCustomEvent('Clicked', 'card-type-' + type);
    },
    deleteBtnClicked: function(serial, name, index) {
      console.log('serial: ' + serial)
      this.$emit('delete-btn-clicked', { serial: serial, name: name, index: index });
    },
    parseDateStr: function(dStr) {
      let t = moment(dStr);
      return t.format('YYYY/MM/DD(dd)');
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .my-trip-plan-link-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-column-gap: 21px;
    grid-row-gap: 2rem;
    @include smaller-than-extra-large {
      grid-template-columns: repeat(3, 1fr);
    }
    @include smaller-than-large {
      grid-template-columns: repeat(2, 1fr);
    }
    @include smaller-than-medium {
      grid-template-columns: 1fr;
    }
    .trip-plan-link-item {
      position: relative;
      .link-main {
        position: relative;
        height: 193px;
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background: #222;
          opacity: .20;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .info-group {
          position: absolute;
          top: 12px;
          left: 20px;
          color: $color-white;
          width: calc(100% - 76px);
        }
        .name {
          margin-bottom: 8px;
          a {
            color: $color-white;
          }
        }
        .text-group {
          display: flex;
          .text-item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-right: 8px;
            }
            img {
              width: 20px;
              height: 20px;
              object-fit: cover;
              filter: brightness(0) invert(1);
              margin-right: 4px;
            }
          }
        }
      }
      .link-footer {
        height: 25px;
        padding-top: 4px;
      }
      .delete-btn {
        position: absolute;
        top: .8rem;
        right: 14px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        object-fit: cover;
        color: $color-white;
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        >img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
</style>
